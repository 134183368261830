<template>
  <div class="pagination" v-if="totalPages !== 0">
    <button
      class="button button__white button--small"
      v-if="showPrev"
      @click="selectPage(page - 1)"
    >
      <img
        src="@/assets/icons/pagination-arrrow.svg"
        style="transform: rotate(180deg)"
      />
    </button>

    <button
      class="button button__white button--small"
      v-if="showFirstPage"
      @click="selectPage(1)"
    >
      1
    </button>
    <button class="button button__white button--small" v-if="showFirstPage">
      ...
    </button>

    <button
      class="button button__white button--small"
      v-for="page in pages"
      :class="{ 'button__white--active': page.isActive }"
      :key="page.number"
      @click="selectPage(page.number)"
    >
      {{ page.number }}
    </button>

    <button class="button button__white button--small" v-if="showLastPage">
      ...
    </button>
    <button
      class="button button__white button--small"
      v-if="showLastPage"
      @click="selectPage(totalPages)"
    >
      {{ totalPages }}
    </button>

    <button
      class="button button__white button--small"
      v-if="showNext"
      @click="selectPage(page + 1)"
    >
      <img src="@/assets/icons/pagination-arrrow.svg" />
    </button>
  </div>
</template>

<script>
export default {
  name: "PaginationC",
  methods: {
    selectPage(number) {
      this.$store.commit("setPage", number);
      this.$store.dispatch("getApplications");
    },
  },
  computed: {
    showPrev: function () {
      return this.$store.state.page !== 1 || false;
    },
    showNext: function () {
      return this.$store.state.page !== this.$store.state.pages || false;
    },
    totalPages: function () {
      return this.$store.state.pages;
    },
    showFirstPage: function () {
      return this.$store.state.page > 3 || false;
    },
    showLastPage: function () {
      return this.$store.state.page < this.$store.state.pages - 2 || false;
    },
    pages: function () {
      if (this.$store.state.pages === 1) {
        return [
          {
            number: this.$store.state.page,
            isActive: true,
          },
        ];
      }

      if (this.$store.state.pages === 2 && this.$store.state.page !== 2) {
        return [
          {
            number: this.$store.state.page,
            isActive: true,
          },
          {
            number: this.$store.state.page + 1,
            isActive: false,
          },
        ];
      }

      if (this.$store.state.pages === 2 && this.$store.state.page !== 1) {
        return [
          {
            number: this.$store.state.page - 1,
            isActive: false,
          },
          {
            number: this.$store.state.page,
            isActive: true,
          },
        ];
      }

      if (this.$store.state.page === 1) {
        return [
          {
            number: this.$store.state.page,
            isActive: true,
          },
          {
            number: this.$store.state.page + 1,
            isActive: false,
          },
          {
            number: this.$store.state.page + 2,
            isActive: false,
          },
        ];
      }

      if (this.$store.state.page === this.$store.state.pages) {
        return [
          {
            number: this.$store.state.page - 2,
            isActive: false,
          },
          {
            number: this.$store.state.page - 1,
            isActive: false,
          },
          {
            number: this.$store.state.page,
            isActive: true,
          },
        ];
      }

      if (
        this.$store.state.page !== 1 &&
        this.$store.state.page !== this.$store.state.pages
      ) {
        return [
          {
            number: this.$store.state.page - 1,
            isActive: false,
          },
          {
            number: this.$store.state.page,
            isActive: true,
          },
          {
            number: this.$store.state.page + 1,
            isActive: false,
          },
        ];
      }

      return false;
    },
    page: function () {
      return this.$store.state.page;
    },
  },
};
</script>
